
export default function Split({index}) {
    // #373737 rgb(35,40,40)
    return (
        
        <svg id={`wave${index}`} class={`split-svg project-detail-split-${index}`}  viewBox="0 0 1440 490" version="1.1">
            <defs>
            <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgb(60,62,70)" offset="0%"/>
        <stop stop-color="rgb(60,62,70)" offset="100%"/>
        </linearGradient>
        </defs>
        <path fill="url(#sw-gradient-0)" d="M0,441L120,432.8C240,425,480,408,720,343C960,278,1200,163,1440,122.5C1680,82,1920,114,2160,138.8C2400,163,2640,180,2880,212.3C3120,245,3360,294,3600,261.3C3840,229,4080,114,4320,65.3C4560,16,4800,33,5040,40.8C5280,49,5520,49,5760,106.2C6000,163,6240,278,6480,310.3C6720,343,6960,294,7200,253.2C7440,212,7680,180,7920,187.8C8160,196,8400,245,8640,277.7C8880,310,9120,327,9360,318.5C9600,310,9840,278,10080,253.2C10320,229,10560,212,10800,245C11040,278,11280,359,11520,367.5C11760,376,12000,310,12240,245C12480,180,12720,114,12960,89.8C13200,65,13440,82,13680,147C13920,212,14160,327,14400,359.3C14640,392,14880,343,15120,318.5C15360,294,15600,294,15840,261.3C16080,229,16320,163,16560,179.7C16800,196,17040,294,17160,343L17280,392L17280,490L17160,490C17040,490,16800,490,16560,490C16320,490,16080,490,15840,490C15600,490,15360,490,15120,490C14880,490,14640,490,14400,490C14160,490,13920,490,13680,490C13440,490,13200,490,12960,490C12720,490,12480,490,12240,490C12000,490,11760,490,11520,490C11280,490,11040,490,10800,490C10560,490,10320,490,10080,490C9840,490,9600,490,9360,490C9120,490,8880,490,8640,490C8400,490,8160,490,7920,490C7680,490,7440,490,7200,490C6960,490,6720,490,6480,490C6240,490,6000,490,5760,490C5520,490,5280,490,5040,490C4800,490,4560,490,4320,490C4080,490,3840,490,3600,490C3360,490,3120,490,2880,490C2640,490,2400,490,2160,490C1920,490,1680,490,1440,490C1200,490,960,490,720,490C480,490,240,490,120,490L0,490Z"/>
        </svg>

    );
    }