import React, { useState } from 'react';
// About component
const About = () => (
    <div className="content-container">
      <div className="section-container">
          <div className="section-content">
          <h1>About</h1>
          <p>This is the About page.</p>
          </div>
      </div>
    </div>
  );
  export default About;