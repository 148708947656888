
export default function Split({index}) {
    // #373737 rgb(35,40,40)
    return (
        
        <svg className="contact-background" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 800" opacity="1" width="800" height="800">
            <g stroke-width="1" stroke="hsla(168, 81%, 52%, 0.44)" fill="none">
        <circle r="3" cx="200" cy="200" fill="hsla(168, 81%, 52%, 0.44)" stroke="none" opacity="0.35">
            </circle>
        <circle r="3" cx="400" cy="200" fill="hsla(168, 81%, 52%, 0.44)" stroke="none">
            </circle>
        <circle r="3" cx="600" cy="200" fill="hsla(168, 81%, 52%, 0.44)" stroke="none" opacity="0.35">
            </circle>
        <circle r="3" cx="200" cy="400" fill="hsla(168, 81%, 52%, 0.44)" stroke="none">
            </circle>
        <circle r="3" cx="400" cy="400" fill="hsla(168, 81%, 52%, 0.44)" stroke="none" opacity="0.35">
            </circle>
        <circle r="3" cx="600" cy="400" fill="hsla(168, 81%, 52%, 0.44)" stroke="none" opacity="0.35">
            </circle>
        <circle r="3" cx="200" cy="600" fill="hsla(168, 81%, 52%, 0.44)" stroke="none" opacity="0.35">
            </circle>
        <circle r="3" cx="400" cy="600" fill="hsla(168, 81%, 52%, 0.44)" stroke="none" opacity="0.35">
            </circle>
        <circle r="3" cx="600" cy="600" fill="hsla(168, 81%, 52%, 0.44)" stroke="none" opacity="0.35">
            </circle>
        </g>
        </svg>
    );
    }