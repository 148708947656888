
export default function Split() {
    return (
        <svg id="wave-cert" class="split-svg"  viewBox="0 0 1440 310" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
            <linearGradient id="sw-gradient-cert" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgb(35,40,40)" offset="0%">
            </stop>
        <stop stop-color="#3f3f3f" offset="100%">
            </stop>
        </linearGradient>
        </defs>
       <path  fill="url(#sw-gradient-cert)" d="M0,62L48,93C96,124,192,186,288,201.5C384,217,480,186,576,149.8C672,114,768,72,864,51.7C960,31,1056,31,1152,51.7C1248,72,1344,114,1440,155C1536,196,1632,238,1728,242.8C1824,248,1920,217,2016,201.5C2112,186,2208,186,2304,155C2400,124,2496,62,2592,31C2688,0,2784,0,2880,10.3C2976,21,3072,41,3168,51.7C3264,62,3360,62,3456,56.8C3552,52,3648,41,3744,46.5C3840,52,3936,72,4032,103.3C4128,134,4224,176,4320,180.8C4416,186,4512,155,4608,129.2C4704,103,4800,83,4896,87.8C4992,93,5088,124,5184,149.8C5280,176,5376,196,5472,196.3C5568,196,5664,176,5760,175.7C5856,176,5952,196,6048,201.5C6144,207,6240,196,6336,201.5C6432,207,6528,227,6624,206.7C6720,186,6816,124,6864,93L6912,62L6912,310L6864,310C6816,310,6720,310,6624,310C6528,310,6432,310,6336,310C6240,310,6144,310,6048,310C5952,310,5856,310,5760,310C5664,310,5568,310,5472,310C5376,310,5280,310,5184,310C5088,310,4992,310,4896,310C4800,310,4704,310,4608,310C4512,310,4416,310,4320,310C4224,310,4128,310,4032,310C3936,310,3840,310,3744,310C3648,310,3552,310,3456,310C3360,310,3264,310,3168,310C3072,310,2976,310,2880,310C2784,310,2688,310,2592,310C2496,310,2400,310,2304,310C2208,310,2112,310,2016,310C1920,310,1824,310,1728,310C1632,310,1536,310,1440,310C1344,310,1248,310,1152,310C1056,310,960,310,864,310C768,310,672,310,576,310C480,310,384,310,288,310C192,310,96,310,48,310L0,310Z">
            </path>
        </svg>
    );
    }